import {Swiper, Navigation, FreeMode, Autoplay, Pagination} from "swiper";

$(function () {
    new Swiper('#featured-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".featured-button-next",
            prevEl: ".featured-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper('#featured-slider-2', {
        modules: [Navigation],
        navigation: {
            nextEl: ".featured-button-next",
            prevEl: ".featured-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper('#opinions-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".opinions-button-next",
            prevEl: ".opinions-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.2,
            },
            1200: {
                slidesPerView: 2.75,
            },
        },
    });

    new Swiper('#press-slider', {
        modules: [Navigation, Autoplay],
        navigation: {
            nextEl: ".press-button-next",
            prevEl: ".press-button-prev",
        },
        slidesPerView: 6,
        loop: true,
        autoplay: {
            delay: 2500,
        },
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 3.5,
            },
            768: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 6,
            },
        },
    });

    new Swiper('#video-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".video-button-next",
            prevEl: ".video-button-prev",
        },
        slidesPerView: 4,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.75,
            },
            1200: {
                slidesPerView: 4,
            },
        },
    });

    new Swiper('#video-categories-slider', {
        modules: [FreeMode, Navigation],
        navigation: {
            nextEl: ".category-button-next",
            prevEl: ".category-button-prev",
        },
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        speed: 800,
        grabCursor: true,
    });

    new Swiper('#video-categories-slider-2', {
        modules: [Navigation],
        navigation: {
            nextEl: ".category-button-next",
            prevEl: ".category-button-prev",
        },
        slidesPerView: 6,
        spaceBetween: 16,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 6,
            },
        },
    });

    new Swiper('#press-clips-slider', {
        modules: [Navigation],
        navigation: {
            nextEl: ".clips-button-next",
            prevEl: ".clips-button-prev",
        },
        slidesPerView: 3,
        spaceBetween: 32,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.2,
            },
            1200: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper('#subscription-slider', {
        modules: [Navigation,Pagination],
        navigation: {
            nextEl: ".subscription-button-next",
            prevEl: ".subscription-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 16,
        speed: 800,
        grabCursor: true,
        breakpoints: {
            0: {
                slidesPerView: 1.25,
            },
            768: {
                slidesPerView: 2.75,
            },
            1200: {
                slidesPerView: 3.75,
            },
        },
    });
});
