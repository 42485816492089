$(function () {
    let $counter = $('#uq-counter');

    if ($counter.length > 0) {
        const TIME_LIMIT = $counter.attr('data-counter');

        let updateTimer = function() {
            let future = Date.parse(TIME_LIMIT);
            let now = new Date();
            let diff = future - now;

            let days = Math.floor(diff / (1000 * 60 * 60 * 24));
            let hours = Math.floor(diff / (1000 * 60 * 60));
            let mins = Math.floor(diff / (1000 * 60));

            let d = days;
            let h = hours - days * 24;
            let m = mins - hours * 60;

            let html = '<div class="time time-day"><span class="number">' + d + '</span><span class="text">días :</span></div>' +
                '<div class="time time-hour"><span class="number">' + h + '</span><span class="text">horas :</span></div>' +
                '<div class="time time-min"><span class="number">' + m + '</span><span class="text">minutos</span></div>';

            let timerLabelHtmlElement = document.getElementById("base-timer-label");
            if (timerLabelHtmlElement.innerHTML !== html) {
                timerLabelHtmlElement.innerHTML = html;
            }
        }

        updateTimer();

        setInterval(updateTimer, 1000);
    }
});