try {
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = require('bootstrap');

    require('@popperjs/core');

    require('./components/Menu');
    require('./components/Slider');
    require('./components/Timer');
} catch (e) {
    console.error(e);
}

import { Tab, Collapse, Dropdown, Button} from 'bootstrap';
import AOS from 'aos/dist/aos';

$(function () {
    AOS.init({
        startEvent: 'DOMContentLoaded',
        once: true,
        duration: 800,
        offset: 20,
        disable: window.matchMedia('(prefers-reduced-motion: reduce)').matches
    });

    let $preloader = $('[data-preloader="load"]');

    if ($preloader.length > 0) {
        setTimeout((e) => {
            $('body').addClass('loaded');
            $preloader.addClass('loaded');
        }, 150);
    }

    function setMainContentHeight() {
        let $main = $('main');
        let $header = $('#top');
        let $footer = $('#footer');
        let $window_h = window.innerHeight;
        let $404_page = $('.uq-heading-404');

        if ($window_h > $main.outerHeight()) {
            $main.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight())
        }

        if ($404_page.length > 0) {
            $404_page.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight())
        }
    }

    let $sameHeightDivs = $('#featured-slider .swiper-slide > div');

    function setSameHeight() {
        let minHeight = 0;

        $sameHeightDivs.each(function () {
            minHeight = Math.max(minHeight, $(this).outerHeight());
        });

        $sameHeightDivs.css({'min-height': minHeight + 'px'});
    }

    function resetSameHeight() {
        $sameHeightDivs.css({'min-height': 'unset'});
    }

    setSameHeight();
    setMainContentHeight();

    $(window).on('resize', function (e) {
        setMainContentHeight();

        if (window.innerWidth > 574) {
            resetSameHeight();
            setSameHeight();
        }
    })
})
